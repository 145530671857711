<template>
  <div>
    <div
      class="
        fixed
        top-0
        left-0
        z-50
        w-screen
        h-screen
        flex
        items-center
        justify-center
      "
    >
      <div
        class="
          bg-white
          border
          py-2
          px-5
          rounded-lg
          flex
          items-center
          flex-col
          shadow-xl
        "
      >
        <div class="mt-2 text-center">
          <p v-if="nombreLista == ''" class="text-lg font-bold">
            ¿Guardar lista generada?
          </p>
          <p v-else class="text-lg font-bold">¿Modificar lista?</p>
          <div class="text-base mt-2 text-center flex">
            <p class="mr-1">Nombre lista:</p>
            <input
              class="border"
              type="text"
              name=""
              id=""
              v-model="listaNombre"
            />
          </div>
          <div class="flex justify-between mx-2 mt-5 mb-2">
            <Botonp
              class="text-sm"
              botonpText="Guardar"
              @click="$emit('guardarListaGenerada', listaNombre)"
            ></Botonp>
            <Botonp
              class="text-sm"
              botonpText="Cerrar"
              @click="$emit('cerrarVentana', false)"
            ></Botonp>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Botonp from "@/components/botonPrimario";
export default {
  components: {
    Botonp
  },
  props: {
    nombreLista: String
  },
  data() {
    return {
      listaNombre: this.nombreLista
    }
  }
}
</script>

<style>
</style>