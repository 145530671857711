<template>
	<div>
		<div
			class="
        flex
        items-center
        mb-4
        bg-verdedark
        w-full
        text-white
        fixed
        md:static
      "
		>
			<span class="ml-2 font-bold py-2">Lista de Precios</span>
		</div>
		<div class="flex justify-between mt-16">
			<div class="ml-12 rounded-lg border-gris border-2 w-1/6 h-40">
				<div class="flex justify-center mt-3">
					<p class="font-bold text-xl">Tipo de Lista</p>
				</div>
				<div class="flex mt-6 ml-4">
					<input
						type="radio"
						name="tipoLista"
						id=""
						value="rubro"
						v-model="tipoBusqueda"
					/>
					<p class="ml-2">Rubros</p>
				</div>
				<div class="flex ml-4">
					<input
						type="radio"
						name="tipoLista"
						id=""
						value="producto"
						v-model="tipoBusqueda"
					/>
					<p class="ml-2">Productos</p>
				</div>
				<div class="flex ml-4 mb-6">
					<input
						type="radio"
						name="tipoLista"
						id=""
						value="lista"
						v-model="tipoBusqueda"
					/>
					<p class="ml-2">Listas Guardadas</p>
				</div>
			</div>
			<div class="flex justify-center items-center">
				<div class="w-full" v-if="tipoBusqueda == 'rubro'">
					<listarubro @solicitarListaPrecios="solicitarListaPrecios" />
				</div>
				<div v-if="tipoBusqueda == 'producto'">
					<listaproducto
						@addProducto="addProductoParent"
						@solicitarListaPrecios="solicitarListaPrecios"
					/>
				</div>
				<div v-if="tipoBusqueda == 'lista'">
					<listaguardada
						@cargaData="addListaGuardada"
						@solicitarListaPrecios="solicitarListaPrecios"
					/>
				</div>
			</div>
		</div>
		<div
			v-if="
				(tipoBusqueda == 'producto' || tipoBusqueda == 'lista') &&
					listaProductoTable.length > 0
			"
			class="mt-10 flex justify-center mb-20"
		>
			<table id="producto" class="table-auto mt-4">
				<thead>
					<tr>
						<th></th>
						<th class="border-2 px-4 py-2">#</th>
						<th class="border-2 px-4 py-2">{{ columHeader }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(producto, k) in listaProductoTable" :key="k">
						<td scope="row">
							<svg
								@click="deleteRow(listaProductoTable.length - k - 1)"
								class="
                  hover:opacity-100
                  cursor-pointer
                  fill-current
                  text-red-500
                  opacity-75
                "
								width="26"
								height="26"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="delete_sweep_24px">
									<path
										id="icon/content/delete_sweep_24px"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
									/>
								</g>
							</svg>
						</td>

						<td class="border-2 px-4 py-4">
							{{ listaProductoTable.length - k }}
						</td>
						<td class="border-2 px-4 py-4">
							{{
								firstLetterUpperCase(
									listaProductoTable[listaProductoTable.length - k - 1]
								)
							}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-if="guardarView">
			<guardarlista
				:nombreLista="listaGuardadaNombre"
				@cerrarVentana="cerrarVentanaGuardar"
				@guardarListaGenerada="guardarListaGenerada"
			/>
		</div>
	</div>
</template>

<script>
import listarubro from "@/components/listaPrecioRubro";
import listaproducto from "@/components/listaPrecioProducto";
import listaguardada from "@/components/listaPrecioGuardadas";
import { firstLetterUpperCase } from "../functions.js";
import rubroTipoCambio from "@/mixins/rubroTipoCambio.js";
import guardarlista from "@/components/guardarListaComponents";

export default {
	components: {
		listarubro,
		listaproducto,
		listaguardada,
		guardarlista
	},
	data() {
		return {
			guardarView: false,
			tipoBusqueda: "rubro",
			listaProductoNombre: [],
			listaProductoTable: [],
			listaProductoGuardada: [],
			listaProductoIdProducto: [],
			listaProductoIdGuardas: [],
			listaRubrosSeleccionados: [],
			columHeader: "",
			tasadia: 0,
			listaGuardadaTipo: "",
			listaGuardadaNombre: "",
			infoListaPrecio: {
				id: 0,
				tipoLista: ""
			}
		};
	},
	mixins: [rubroTipoCambio],
	watch: {
		tipoBusqueda: {
			handler() {
				this.llenarListaProductoGuardada();
			}
		}
	},
	async created() {
		await this.tipoCambioQuery();
	},
	methods: {
		firstLetterUpperCase,
		generarListaRubro(listaArray, nombre, tipoLista, modificarLista) {
			let listaPrecio = [];
			listaArray.forEach(element => {
				listaPrecio.push({ rubro: element });
			});
			if (!modificarLista) {
				this.guardarListaMutation({
					nombre: nombre,
					tipoLista: tipoLista,
					lista: listaPrecio
				});
			} else {
				this.modificarListaMutation(
					{
						nombre: nombre,
						tipoLista: tipoLista,
						lista: listaPrecio
					},
					this.infoListaPrecio.id
				);
			}
		},
		generarListaProduto(
			listaArrayIdProductos,
			listaArrayProductoNombre,
			nombre,
			tipoLista,
			modificarLista
		) {
			let listaPrecio = [];
			for (let i = 0; i < listaArrayIdProductos.length; i++) {
				listaPrecio.push({
					id: listaArrayIdProductos[i],
					producto: listaArrayProductoNombre[i]
				});
			}
			if (!modificarLista) {
				this.guardarListaMutation({
					nombre: nombre,
					tipoLista: tipoLista,
					lista: listaPrecio
				});
			} else {
				this.modificarListaMutation(
					{
						nombre: nombre,
						tipoLista: tipoLista,
						lista: listaPrecio
					},
					this.infoListaPrecio.id
				);
			}
		},
		guardarListaGenerada(event) {
			let modificarLista = false;
			switch (this.tipoBusqueda) {
				case "rubro":
					this.generarListaRubro(
						this.listaRubrosSeleccionados,
						event,
						this.tipoBusqueda,
						modificarLista
					);
					break;
				case "producto":
					this.generarListaProduto(
						this.listaProductoIdProducto,
						this.listaProductoNombre,
						event,
						this.tipoBusqueda,
						modificarLista
					);
					break;
				case "lista":
					if (event === this.listaGuardadaNombre) {
						modificarLista = true;
					}
					if (this.listaGuardadaTipo === "producto") {
						this.generarListaProduto(
							this.listaProductoIdGuardas,
							this.listaProductoGuardada,
							event,
							this.listaGuardadaTipo,
							modificarLista
						);
					} else {
						this.generarListaRubro(
							this.listaProductoGuardada,
							event,
							this.listaGuardadaTipo,
							modificarLista
						);
					}
					break;
			}
		},
		cerrarVentanaGuardar(event) {
			this.guardarView = event;
		},
		solicitarListaPrecios(event) {
			let makeQuery = true;
			if (
				this.tipoBusqueda == "producto" &&
				this.listaProductoIdProducto.length < 1
			) {
				makeQuery = false;
			}
			if (
				this.tipoBusqueda == "lista" &&
				this.listaProductoIdGuardas.length < 1
			) {
				makeQuery = false;
			}

			if (makeQuery) {
				this.listaGuardadaNombre = "";
				switch (this.tipoBusqueda) {
					case "rubro":
						this.listaRubrosSeleccionados = event.lista;
						this.listaPreciosQuery(event.tipoLista, event.lista);
						break;
					case "producto":
						this.listaPreciosQuery(
							event.tipoLista,
							this.listaProductoIdProducto
						);
						break;
					case "lista":
						this.listaGuardadaTipo = event.tipoLista;
						this.listaGuardadaNombre = event.nombreLista;
						this.listaPreciosQuery(
							event.tipoLista,
							this.listaProductoIdGuardas
						);
						break;
				}
			} else {
				alert("Tiene que escoger al menos un producto");
			}
		},
		deleteRow(index) {
			this.listaProductoTable.splice(index, 1);
			if (this.tipoBusqueda === "producto") {
				this.listaProductoNombre.splice(index, 1);
				this.listaProductoIdProducto.splice(index, 1);
			}
			if (this.tipoBusqueda === "lista") {
				this.listaProductoGuardada.splice(index, 1);
				this.listaProductoIdProducto.splice(index, 1);
			}
		},
		llenarListaProductoGuardada() {
			if (this.tipoBusqueda === "producto") {
				this.listaProductoTable = JSON.parse(
					JSON.stringify(this.listaProductoNombre)
				);
			}
			if (this.tipoBusqueda === "lista") {
				this.listaProductoTable = JSON.parse(
					JSON.stringify(this.listaProductoGuardada)
				);
			}
		},
		addProductoParent(event) {
			let productoRepetido = [];
			productoRepetido = this.listaProductoNombre.filter(
				e => e.producto == event[0].producto
			);
			if (productoRepetido.length > 0) {
				alert("Producto repetido");
			} else {
				this.columHeader = "Producto";
				this.listaProductoIdProducto.push(event[0].id);
				this.listaProductoNombre.push(event[0].producto);
				this.llenarListaProductoGuardada();
			}
		},
		addListaGuardada(event) {
			this.columHeader = firstLetterUpperCase(event.tipo);
			this.infoListaPrecio.id = event.id;
			this.infoListaPrecio.tipoLista = event.tipo;
			this.listaGuardadaNombre = event.nombre;
			if (event.tipo === "rubro") {
				this.listaProductoGuardada = event.lista.map(e => e.rubro);
				this.listaProductoIdGuardas = this.listaProductoGuardada;
			} else {
				this.listaProductoIdGuardas = event.lista.map(e => e.id);
				this.listaProductoGuardada = event.lista.map(e => e.producto);
			}

			this.llenarListaProductoGuardada();
		},
		async listaPreciosQuery(tipoLista, lista) {
			this.$store.state.isLoading = true;
			this.guardarView = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/listaPrecios.gql"),
					variables: {
						data: {
							tipoLista: tipoLista,
							lista: lista
						}
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					let routeData;
					let dataPDF = [];
					this.$store.state.isLoading = false;
					dataPDF = data.data.listaPrecios.map(e => {
						return {
							producto: e.producto,
							unidad: e.unidad,
							precioUsd: e.precio_usd,
							precioBs: e.precio_bs
						};
					});
					this.$store.commit(
						"crearDataPdf",
						JSON.parse(JSON.stringify(dataPDF))
					);
					routeData = this.$router.resolve({
						name: "PdfUsdBsListaPrecios"
					});
					window.open(routeData.href, "_blank");
					this.mostrarPDF = false;
					console.log(this.$store.state.pdfData);
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		async guardarListaMutation(objetoLista) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/guardarListaPrecio.gql"),
					variables: {
						input: {
							lista_nombre: objetoLista.nombre,
							lista_precio: JSON.stringify(objetoLista)
						}
					}
				})
				.then(() => {
					this.guardarView = false;
					this.$store.state.isLoading = false;
					alert("Lista guardada con exito");
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.error(error);
					alert(
						"Error al guardar lista, el nombre tiene que ser único, si el nombre ingresado es único vuelva a intentarlo o verifique su conexión a internet"
					);
				});
		},
		async modificarListaMutation(objetoLista, idLista) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/modificarListaPrecio.gql"),
					variables: {
						id: parseInt(idLista),
						input: {
							lista_nombre: objetoLista.nombre,
							lista_precio: JSON.stringify(objetoLista)
						}
					}
				})
				.then(() => {
					this.guardarView = false;
					this.$store.state.isLoading = false;
					alert("Lista modificada con exito");
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.error(error);
					alert(
						"Error al guardar lista, el nombre tiene que ser único, si el nombre ingresado es único vuelva a intentarlo o verifique su conexión a internet"
					);
				});
		}
	}
};
</script>

<style></style>
