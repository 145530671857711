<template>
  <div class="border-2 rounded-lg border-gris mr-20">
    <div class="flex justify-center mt-3 mb-6">
      <p class="font-bold text-xl mx-8">Lista de Precios</p>
    </div>
    <div class="flex ml-4">
      <input
        type="radio"
        name="tipoListaMoneda"
        id=""
        value="bs"
        v-model="$store.state.tipoPDFListaPrecios"
      />
      <p class="ml-2">BS</p>
    </div>
    <div class="flex ml-4">
      <input
        type="radio"
        name="tipoListaMoneda"
        id=""
        value="usd"
        v-model="$store.state.tipoPDFListaPrecios"
      />
      <p class="ml-2">USD</p>
    </div>
    <div class="flex ml-4 mb-4">
      <input
        type="radio"
        name="tipoListaMoneda"
        id=""
        value="bsUsd"
        v-model="$store.state.tipoPDFListaPrecios"
      />
      <p class="ml-2">BS y USD</p>
    </div>
    <div class="flex justify-center mb-6">
      <button
        @click="$emit('solicitarListaPrecios')"
        class="
          border border-primaB
          rounded-lg
          bg-prima
          hover:bg-orange-300
          text-black
          font-bold
          px-2
          py-1
          sm:py-2
          sm:px-4
          items-center
          focus:outline-none
        "
      >
        Generar Lista
      </button>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>