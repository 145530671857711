<template>
	<div class="flex">
		<div class="rounded-lg border-gris border-2">
			<div class="flex justify-center mt-3 mb-6">
				<p class="font-bold text-xl mx-8">Listas de Precios Guardadas</p>
			</div>
			<div v-if="listasGuardadasObject.length > 0" class="w-full">
				<div class="flex justify-center">
					<table id="producto" class="table-auto mt-4">
						<thead>
							<tr>
								<th class="border-2 px-4 py-2">Listas Guardadas</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="border-2 px-4 py-4">
									<vSelect
										class="
                      w-64
                      bg-white
                      hover:bg-gray-200
                      text-black
                      items-center
                      focus:outline-none
                    "
										:options="listasGuardadasNombre"
										v-model="lista"
									></vSelect>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="flex justify-center my-5">
					<Botonp botonpText="Cargar Lista" @click="eventListaGuardada()" />
				</div>
				<div class="flex justify-center my-5">
					<Botonp botonpText="Borrar Lista" @click="deleteListaGuardada()" />
				</div>
			</div>
			<div class="flex justify-center" v-else>
				<p class="text-base mb-4">No hay listas de precios guardadas</p>
			</div>
		</div>
		<div v-if="listasGuardadasObject.length > 0" class="ml-64">
			<Botonlista @solicitarListaPrecios="solicitarListaParent" />
		</div>
		<div v-else class="mr-76"></div>
	</div>
</template>

<script>
import Botonlista from "@/components/BotonGenerarListaPrecio";
import vSelect from "vue-select";
import Botonp from "@/components/botonPrimario";
import "@/assets/styles/vue-select.css";

export default {
	components: {
		Botonlista,
		vSelect,
		Botonp
	},
	data() {
		return {
			listasGuardadasObject: [],
			listasGuardadasNombre: [],
			lista: "",
			listaGuardadaId: 0
		};
	},
	async created() {
		this.$store.state.isLoading = true;
		await this.listasGuardadasQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		solicitarListaParent() {
			if (this.lista != "") {
				let objectoEventJsonDecode = {};
				let objectoEvent = this.listasGuardadasObject.filter(e => {
					let elementJsonDecode = JSON.parse(e.lista_precio);
					if (elementJsonDecode.nombre == this.lista) {
						return elementJsonDecode;
					}
				});
				objectoEventJsonDecode = JSON.parse(objectoEvent[0].lista_precio);
				this.$emit("solicitarListaPrecios", {
					nombreLista: objectoEventJsonDecode.nombre,
					tipoLista: objectoEventJsonDecode.tipoLista,
					lista: objectoEventJsonDecode.lista,
					idLista: objectoEvent[0].id
				});
			} else {
				alert("Debe elegir una lista.");
			}
		},
		async listasGuardadasQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/listaPreciosGuardadas.gql")
				})
				.then(data => {
					this.listasGuardadasObject = data.data.listaPrecioGuardadaQuery;
					this.listasGuardadasNombre = this.listasGuardadasObject.map(e => {
						let elementJsonDecode = JSON.parse(e.lista_precio);
						return elementJsonDecode.nombre;
					});
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
				});
		},
		eventListaGuardada() {
			if (this.lista != "") {
				let objectoEventJsonDecode = this.objetoListaSeleccionada();
				this.$emit("cargaData", {
					id: objectoEventJsonDecode.id,
					tipo: objectoEventJsonDecode.tipoLista,
					lista: objectoEventJsonDecode.lista
				});
			} else {
				alert("Debe elegir una lista.");
			}
		},
		async deleteListaGuardada() {
			if (this.lista != "") {
				let objectoEventJsonDecode = this.objetoListaSeleccionada();
				if (confirm('¿Borrar lista "' + objectoEventJsonDecode.nombre + '"?')) {
					this.$store.state.isLoading = true;
					await this.$apollo
						.mutate({
							mutation: require("@/graphql/mutations/borrarListaPrecio.gql"),
							variables: {
								id: parseInt(objectoEventJsonDecode.id)
							}
						})
						.then(() => {
							this.$store.state.isLoading = false;
							alert("Lista borrada con exito");
							this.$router.push("ListaPrecios");
							this.$router.go(0);
						})
						.catch(error => {
							this.$store.state.isLoading = false;
							console.error(error);
							alert(
								"Error al borrar lista, vuelva a intentarlo o verifique su conexión a internet"
							);
						});
				}
			} else {
				alert("Debe elegir una lista.");
			}
		},
		objetoListaSeleccionada() {
			let objectoEvent = this.listasGuardadasObject.filter(e => {
				let elementJsonDecode = JSON.parse(e.lista_precio);
				if (elementJsonDecode.nombre == this.lista) {
					return elementJsonDecode;
				}
			});
			let objetoAuxiliar = JSON.parse(objectoEvent[0].lista_precio);
			return {
				id: objectoEvent[0].id,
				nombre: objetoAuxiliar.nombre,
				tipoLista: objetoAuxiliar.tipoLista,
				lista: objetoAuxiliar.lista
			};
		}
	}
};
</script>

<style></style>
