<template>
  <div class="flex">
    <div class="rounded-lg border-gris border-2">
      <div class="flex justify-center mt-3 mb-6">
        <p class="font-bold text-xl mx-8">Rubros y Productos Disponibles</p>
      </div>
      <div class="mx-10">
        <div>
          <table id="producto" class="table-auto mt-4">
            <thead>
              <tr>
                <th class="border-2 px-4 py-2">Rubro</th>
                <th class="border-2 px-4 py-2">Producto</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-2 px-4 py-4">
                  <vSelect
                    class="
                      w-32
                      bg-white
                      hover:bg-gray-200
                      text-black
                      items-center
                      focus:outline-none
                    "
                    :options="opcionesRubro"
                    v-model="rubro"
                    @input="opcionesProductoPrecio(rubro)"
                  ></vSelect>
                </td>
                <td class="border-2 px-4 py-4">
                  <vSelect
                    class="
                      w-64
                      bg-white
                      hover:bg-gray-200
                      text-black
                      items-center
                      focus:outline-none
                    "
                    :options="nuevoProducto"
                    v-model="producto"
                    :key="selectRefresh"
                  >
                  </vSelect>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex justify-center my-5">
          <Botonp botonpText="Añadir Producto" @click="addProductoChild" />
        </div>
      </div>
    </div>
    <div class="ml-32">
      <Botonlista @solicitarListaPrecios="solicitarListaParent" />
    </div>
  </div>
</template>

<script>
import rubroTipoCambio from "@/mixins/rubroTipoCambio.js";
import Botonlista from "@/components/BotonGenerarListaPrecio";
import vSelect from "vue-select";
import Botonp from "@/components/botonPrimario";
import "@/assets/styles/vue-select.css";

export default {
  components: {
    Botonlista,
    vSelect,
    Botonp
  },
  data() {
    return {
      rubrosTotal: [],
      productoObjeto: [],
      rubro: "",
      producto: "",
      nuevoProducto: [],
      selectRefresh: true
    }
  },
  mixins: [rubroTipoCambio],
  async created() {
    await this.rubroTotalQuery();
  },
  methods: {
    solicitarListaParent() {
      this.$emit("solicitarListaPrecios", {
        tipoLista: "producto"
      });
    },
    addProductoChild() {
      if (this.producto != "") {
        this.$emit(
          "addProducto",
          this.productoObjeto.filter(e => e.producto == this.producto)
        );
      }
      this.producto = "";
      this.selectRefresh = !this.selectRefresh;
    },
    async opcionesProductoPrecio(rubro) {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: require("@/graphql/queries/productoRubro.gql"),
          variables: {
            rubro: rubro
          }
        })
        .then(data => {
          this.nuevoProducto = [];
          this.productoObjeto = data.data.productoRubro;
          this.nuevoProducto = this.productoObjeto.map(e => e.producto);
          this.selectRefresh = !this.selectRefresh;
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    }
  },
}
</script>

<style>
</style>