<template>
  <div class="flex">
    <div class="rounded-lg border-gris border-2 mb-12">
      <div class="flex justify-center mt-3 mb-6">
        <p class="font-bold text-xl mx-8">Rubros Disponibles</p>
      </div>
      <div
        class="flex"
        v-for="(rubro, k) in rubrosTotal"
        :key="k"
        :class="k >= rubrosTotal.length - 1 ? 'mb-6' : ''"
      >
        <input
          class="ml-4"
          type="checkbox"
          name=""
          id=""
          :value="rubro.rubro"
          v-model="rubrosEscogidos"
        />
        <p class="ml-2">{{ firstLetterUpperCase(rubro.rubro) }}</p>
      </div>
    </div>
    <div class="ml-68">
      <Botonlista @solicitarListaPrecios="solicitarListaParent" />
    </div>
  </div>
</template>

<script>
import rubroTipoCambio from "@/mixins/rubroTipoCambio.js";
import { firstLetterUpperCase } from "../functions.js";
import Botonlista from "@/components/BotonGenerarListaPrecio";

export default {
  components: {
    Botonlista
  },
  data() {
    return {
      rubrosTotal: [],
      rubrosEscogidos: []
    };
  },
  mixins: [rubroTipoCambio],
  async created() {
    await this.rubroTotalQuery();
  },
  methods: {
    firstLetterUpperCase,
    solicitarListaParent() {
      if (this.rubrosEscogidos.length > 0) {
        this.$emit("solicitarListaPrecios", {
          tipoLista: "rubro",
          lista: this.rubrosEscogidos
        });
      } else {
        alert("Tiene que escoger al menos un rubro");
      }
    }
  }
};
</script>

<style></style>
